<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectConfirmedServiceFormCheckbox"
                  :indeterminate="isSelectConfirmedServiceFormCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedServiceForms.length"
              >
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'ServiceForm')"
                  v-b-modal.modal-update-status-confirmed-service-form-bulk
                >
                  Update Status
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('delete', 'ServiceForm')"
                  @click="deleteBulkServiceForms"
                >
                  Delete Service Form(s)
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectConfirmedServiceFormCheckbox"
                :indeterminate="isSelectConfirmedServiceFormCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedServiceForms.length"
            >
              <!-- <b-dropdown-item @click="downloadBulkCSV">
                Download CSV
              </b-dropdown-item> -->
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ServiceForm')"
                v-b-modal.modal-update-status-confirmed-service-form-bulk
              >
                Update Status
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('delete', 'ServiceForm')"
                @click="deleteBulkServiceForms"
              >
                Delete Service Form(s)
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-confirmed-service-form"
                ref="filter_dropdown_confirmed_service_form"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <service-form-filters
                  :status-filter.sync="statusFilter"
                  :payment-status-filter.sync="paymentStatusFilter"
                  :created-at.sync="createdAtFilter"
                  :service-date.sync="serviceDateFilter"
                  :status-options="statusOptions"
                  :payment-status-options="paymentStatusOptions"
                  :duty-officer.sync="dutyOfficerFilter"
                  :duty-officer-options="dutyOfficerOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="dutyOfficerFilter.length || createdAtFilter || serviceDateFilter || statusFilter.length || paymentStatusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="dutyOfiicerVal in dutyOfficerFilter"
                  :key="dutyOfiicerVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromDutyOfficerFilter(dutyOfiicerVal)"
                  >
                    <span class="">{{ resolveDutyOfficerName(dutyOfiicerVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in paymentStatusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromPaymentStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-if="serviceDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="serviceDateFilter = ''"
                  >
                    {{ serviceDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refConfirmedServiceFormsTable"
        class="position-relative event_list min-height-200"
        :items="fetchServiceForms"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedServiceForms.includes(data.item._id)"
              @change="toggleSelectedServiceForms(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item._id } }"
              >
                {{ data.item.stringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.customerContact"
              class=""
            >
              (65) {{ data.item.customerContact }}
            </span>
          </div>
        </template>

        <template #cell(agentName)="data">
          <div
            v-if="data.item.agentName"
            class="text-nowrap"
          >
            <span class="">{{ data.item.agentName }}</span>
            <br>
            <span
              v-if="data.item.agentContact"
              class=""
            >
              (65) {{ data.item.agentContact }}
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(paymentRemarks)="data">
          <div
            class="text-nowrap"
          >
            <span class="">{{ data.item.paymentStatus || '-' }}</span>
            <br>
            <span
              class=""
            >
              {{ data.item.paymentRemarks || '-' }}
            </span>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.createdBy ? data.item.createdBy.name : '' }}</span>
            <br>
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveServiceFormStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('create', 'ServiceForm') || canViewThisAction('show', 'ServiceForm') || canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')
            || canViewThisAction('update-deceased', 'ServiceForm') || canViewThisAction('update-service-description', 'ServiceForm') || canViewThisAction('update-agent', 'ServiceForm')
            || canViewThisAction('update-attachment', 'ServiceForm') || canViewThisAction('update-duty-officer', 'ServiceForm') || canViewThisAction('update-status', 'ServiceForm')
            || canViewThisAction('update-payment', 'ServiceForm') || canViewThisAction('delete', 'ServiceForm')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'ServiceForm')"
              :to="{ name: 'operation-service-forms-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')
                || canViewThisAction('update-deceased', 'ServiceForm') || canViewThisAction('update-service-description', 'ServiceForm') || canViewThisAction('update-agent', 'ServiceForm')
                || canViewThisAction('update-attachment', 'ServiceForm') || canViewThisAction('update-duty-officer', 'ServiceForm') || canViewThisAction('update-status', 'ServiceForm')
                || canViewThisAction('update-payment', 'ServiceForm')"
              :to="{ name: 'operation-service-forms-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'ServiceForm')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('create', 'ServiceForm')"
              :to="{ name: 'operation-service-forms-create', query: { from: data.item._id } }"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalServiceForms > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalServiceForms"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-confirmed-service-form-bulk"
      ref="update-status-modal-confirmed-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="confirmedServiceFormsBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BButton, BFormTag,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useConfirmedServiceFormList from './useConfirmedServiceFormList'
import serviceFormStoreModule from '../serviceFormStoreModule'
import ServiceFormFilters from '../ServiceFormFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ServiceFormFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dutyOfficerOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      bulkStatus: 'completed',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Awaiting Approval', code: 'awaiting approval' },
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_confirmed_service_form.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_confirmed_service_form.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },
    removeFromPaymentStatusFilter(item) {
      const index = this.paymentStatusFilter.indexOf(item)
      if (index > -1) {
        this.paymentStatusFilter.splice(index, 1)
      }
    },

    removeFromDutyOfficerFilter(item) {
      const index = this.dutyOfficerFilter.indexOf(item)
      if (index > -1) {
        this.dutyOfficerFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.createdAtFilter = ''
      this.serviceDateFilter = ''
      this.statusFilter = []
      this.paymentStatusFilter = []
      this.dutyOfficerFilter = []
    },
    resolveDutyOfficerName(id) {
      const user = this.dutyOfficerOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },
    updateBulkStatus() {
      this.$refs.confirmedServiceFormsBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedServiceForms.length; i++) {
            formData.append('serviceForms[]', this.selectedServiceForms[i])
          }

          this.$http.patch('operation/service-forms/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountServiceForm')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected service forms has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteBulkServiceForms() {
      this.$swal({
        title: 'Are you sure?',
        html: 'This will <strong>delete</strong> selected service form(s). This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.selectedServiceForms.length; i++) {
              formData.append('serviceForms[]', this.selectedServiceForms[i])
            }

            this.$http.patch('operation/service-forms/action/bulk/delete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.refetchData()
                this.$emit('emitCountServiceForm')
                this.selectedServiceForms = []
                // this.$nextTick(() => {
                //   this.$refs['update-status-modal'].toggle('#toggle-btn')
                // })
                this.$swal({
                  title: 'Service Form(s) Deleted!',
                  html: 'Selected service form(s) has been deleted',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteServiceForm()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const SERVICE_FORM_APP_STORE_MODULE_NAME = 'app-confirmed-service-forms'

    // Register module
    if (!store.hasModule(SERVICE_FORM_APP_STORE_MODULE_NAME)) store.registerModule(SERVICE_FORM_APP_STORE_MODULE_NAME, serviceFormStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(SERVICE_FORM_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Awaiting Approval', value: 'awaiting approval' },
      { label: 'Completed', value: 'completed' },
      { label: 'Pending', value: 'pending' },
    ]

    const paymentStatusOptions = [
      { label: 'Paid', value: 'Paid' },
      { label: 'Partially Paid', value: 'Partially Paid' },
      { label: 'Unpaid', value: 'Unpaid' },
      { label: 'FSP', value: 'FSP' },
      { label: 'N/A', value: 'N/A' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchServiceForms,
      tableColumns,
      perPage,
      currentPage,
      totalServiceForms,
      serviceFormData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConfirmedServiceFormsTable,
      refetchData,

      toDeletedID,
      deleteServiceForm,

      // UI
      resolveServiceFormStatusVariant,

      // Extra Filters
      statusFilter,
      paymentStatusFilter,
      categoryFilter,
      createdAtFilter,
      serviceDateFilter,
      dutyOfficerFilter,
    } = useConfirmedServiceFormList()

    const selectedServiceForms = ref([])
    const toggleSelectedServiceForms = ServiceFormId => {
      const ServiceFormIndex = selectedServiceForms.value.indexOf(ServiceFormId)

      if (ServiceFormIndex === -1) selectedServiceForms.value.push(ServiceFormId)
      else selectedServiceForms.value.splice(ServiceFormIndex, 1)
    }
    const selectConfirmedServiceFormCheckbox = computed(() => serviceFormData.value.length && (serviceFormData.value.length === selectedServiceForms.value.length))
    const isSelectConfirmedServiceFormCheckboxIndeterminate = computed(() => Boolean(selectedServiceForms.value.length) && serviceFormData.value.length !== selectedServiceForms.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedServiceForms.value = val ? serviceFormData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchServiceForms,
      tableColumns,
      perPage,
      currentPage,
      totalServiceForms,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refConfirmedServiceFormsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteServiceForm,
      serviceFormData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveServiceFormStatusVariant,

      statusOptions,
      paymentStatusOptions,

      // Extra Filters
      statusFilter,
      paymentStatusFilter,
      categoryFilter,
      createdAtFilter,
      serviceDateFilter,
      dutyOfficerFilter,

      selectConfirmedServiceFormCheckbox,
      isSelectConfirmedServiceFormCheckboxIndeterminate,
      selectedServiceForms,
      toggleSelectedServiceForms,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
